import imgT1 from "../assets/image/Doctor Platform/main.png";
import imgT2 from "../assets/image/DuJour/main.png";
import imgT3 from "../assets/image/Shluchim App/main.png";
import imgT4 from "../assets/image/Teacher Platform/main.png";
import imgT5 from "../assets/image/YahadutTV/main.png";
import imgT6 from "../assets/image/iRoomquest/main.png";

export const projects = [
  {
    brand: `Doctor Platform`,
    slug: `doctors`,
    title: `A marketplace platform to connect between Doctors and patients.`,
    categories: [`marketplace`],
    thumbnail: imgT1,
  link : "/portfolio-doctors"
},
  {
    brand: `DuJour`,
    slug: `dujour`,
    title: `E-menu platform for hotels and restaurants.`,
    categories: [`hospitality`],
    thumbnail: imgT2,
  link : "/portfolio-dujour"
},
  {
    brand: `Pause 4 Purpose`,
    slug: `pause4purpose`,
    title: `A schedule and Live classes platform for learning with Rabbis.`,
    categories: [`education`],
    thumbnail: imgT3,
  link : "/portfolio-pause4purpose"
},
  {
    brand: `Teacher Platform`,
    slug: `teachers`,
    title: `A marketplace platform to connect between Doctors and patients.`,
    categories: [`marketplace`, `education`],
    thumbnail: imgT4,
  link : "/portfolio-teachers"
},
  {
    brand: `Yahadut TV`,
    slug: `yahadut-tv`,
    title: `Online platform  of thousands of religious Jewish Torah talks.`,
    categories: [`education`],
    thumbnail: imgT5,
  link : "/portfolio-yahaduttv"
},
  {
    brand: `iRoomquest`,
    slug: `iroomquest`,
    title: `Platform to maanage and supervise your staff and to connect your staff directly to guests.`,
    categories: [`hospitality`],
    thumbnail: imgT6,
  link : "/portfolio-iroomquest"
},
];
